export const COMMON_LABELS = {
  LABEL_ITEM: "COMMON_LABELS.LABEL_ITEM",
  LABEL_ITEMS: "COMMON_LABELS.LABEL_ITEMS",
};
// Calculator selction (home) labels keys
export const LABELKEYS_DASHBOARD = {
  HEADING: "DASHBOARD.TECHNICAL_PUBLICATIONS",
  MAIN_HEADING: "DASHBOARD.MAIN_HEADING",
};

export const ACCESS_STATUS = {
  APPROVED: "Approved",
};

export const LOCATION_BREADCRUMB = {
  SUPPORT_RESOURCES: "DASHBOARD.SUPPORT_RESOURCES",
  TECHNICAL_PUBLICATIONS: "DASHBOARD.TECHNICAL_PUBLICATIONS",
  PUBLICATIONS_SEARCH: "DASHBOARD.PUBLICATIONS_SEARCH",
  DOWNLOADS: "DASHBOARD.DOWNLOADS",
  MY_FAVORITES: "DASHBOARD.MY_FAVORITES",
  MY_LIBRARY: "DASHBOARD.MY_LIBRARY",
  MY_WATCHLIST: "DASHBOARD.MY_WATCHLIST",
};
export const LABELKEYS_GEOCHECK = {
  COUNTRY_SELECTION: "GEOCHECK.COUNTRY_SELECTION",
  MODAL_TITLE: "GEOCHECK.MODAL_TITLE",
  SUB_HEADING: "GEOCHECK.SUB_HEADING",
  CHECK_BOX: "GEOCHECK.CHECK_BOX",
  CANCEL_BUTTON: "GEOCHECK.CANCEL_BUTTON",
  SUBMIT_BUTTON: "GEOCHECK.SUBMIT_BUTTON",
  NO_RESULTS_FOUND: "GEOCHECK.NO_RESULTS_FOUND",
};
//API Paths
export const API_PATHS = {
  CONFIGURATIONS: "configurations",
  LABEL_CONFIG_LABELS: "labels",
  LANG_API_PATH: "/api/v1/config/labels/",
};

export const DEFAULT_LANG = {
  EN_US_LANG: "en-us",
};
//Store action type
export const STORE_ACTION_TYPES = {
  CALCULATOR: "calculator",
  INIT: "init",
  CONFIG_QUERY: "configApi/executeQuery/fulfilled",
  ERROR: "error",
  RESET_QUERY: "resetQuery",
};
//Error Messages
export const ERROR_MESSAGES = {
  CONFIG_NOT_FOUND: "Error: Cannot access configuration files for this app",
  LANG_FILE_NOT_FOUND: "Error: Cannot access language files for this app",
  ERROR_HANDLING_MESSAGE: "ERROR_HANDLING.ERROR_HANDLING_MESSAGE",
  ERROR_HANDLING_SUB_HEADLINE: "ERROR_HANDLING.ERROR_HANDLING_SUB_HEADLINE",
  ERROR_HANDLING_HEADLINE: "ERROR_HANDLING.ERROR_HANDLING_HEADLINE",
  RESTRICT_APPLICATION_TEXT: "ACCESS_RESTRICTION.RESTRICT_APPLICATION_TEXT",
};
export const LANG_API_PATHS = (lang: string) => {
  let apiPath = "";
  if (lang) {
    apiPath = API_PATHS.LABEL_CONFIG_LABELS + "?lang=" + lang;
  }
  return apiPath;
};

export const BRANDING_JS_PATHS = (key: any) => {
  let apiPath = "";
  switch (key) {
    case "226":
      apiPath = "/js/header-footer-techpub-dev.js";
      break;
    case "262":
      apiPath = "/js/header-footer-techpub-qa.js";
      break;
    case "235":
      apiPath = "/js/header-footer-techpub-stage.js";
      break;
    case "75":
      apiPath = "/js/header-footer-techpub-prod.js";
      break;

    default:
      apiPath = "/js/header-footer-techpub-prod.js";
      break;
  }
  return apiPath;
};

export const URLS = {
  HOME_AEROSPACE_URL: "/us/en/secure/myaerospace-home",
  REQUEST_TOOL_ACCESS_URL: "/us/en/secure/access?applicationId=",
};
//Search Results
export const LABELKEYS_SEARCH_RESULTS = {
  PUBLICATION_NUMBER: "SEARCH_RESULTS.PUBLICATION_NUMBER",
  VOLUME: "SEARCH_RESULTS.VOLUME",
  REVISION_DATE_NUMBER: "SEARCH_RESULTS.REVISION_DATE_NUMBER",
  ISSUE_NUMBER: "SEARCH_RESULTS.ISSUE_NUMBER",
  EXPORT_CLASSIFICATION: "SEARCH_RESULTS.EXPORT_CLASSIFICATION",
  RELETED_MODALS: "SEARCH_RESULTS.RELETED_MODALS",
  PART_NUMBERS: "SEARCH_RESULTS.PART_NUMBERS",
  RELETED_APPLICATION: "SEARCH_RESULTS.RELETED_APPLICATION",
  ORGANIZATION: "SEARCH_RESULTS.ORGANIZATION",
  RELETED_PUBLICATION: "SEARCH_RESULTS.RELETED_PUBLICATION",
  REMARKS: "SEARCH_RESULTS.REMARKS",
  REPORT_DISCREPANCY: "SEARCH_RESULTS.REPORT_DISCREPANCY",
  SHOW_LESS: "SEARCH_RESULTS.SHOW_LESS",
  SHOW_MORE: "SEARCH_RESULTS.SHOW_MORE",
  ITEMS: "SEARCH_RESULTS.ITEMS",
  NO_DATA_AVAILABLE: "SEARCH_RESULTS.NO_DATA_AVAILABLE",
  NO_SEARCH_DATA_AVAILABLE: "SEARCH_RESULTS.NO_SEARCH_DATA_AVAILABLE",
  NO_DATE_DATE_RANGE: "SEARCH_RESULTS.NO_DATE_DATE_RANGE",
  EMPTY_SEARCH: "SEARCH_RESULTS.EMPTY_SEARCH",
  NO_DATA_AVAILABLE_SEARCHPAGE: "SEARCH_RESULTS.NO_DATA_AVAILABLE_SEARCHPAGE",
  SEARCHING_NEWKEYWORD: "SEARCH_RESULTS.SEARCHING_NEWKEYWORD",
  ATA_NUMBER: "SEARCH_RESULTS.ATA_NUMBER",
  PUB_ID: "SEARCH_RESULTS.PUB_ID",
  ORIGINAL_DATE: "SEARCH_RESULTS.ORIGINAL_DATE",
  APPLICATION: "SEARCH_RESULTS.APPLICATION",
  EXPORT_CODE: "SEARCH_RESULTS.EXPORT_CODE",
  PUBLICATION_ID: "SEARCH_RESULTS.PUBLICATION_ID",
  MODELS_NUMBER: "SEARCH_RESULTS.MODELS_NUMBER",
  PUBLICATION_TYPE: "SEARCH_RESULTS.PUBLICATION_TYPE",
  START_OVER: "SEARCH_RESULTS.START_OVER",
  VIEW_ALL: "SEARCH_RESULTS.VIEW_ALL",
  COMMA_SEPARATOR: ",",
  SPACING: " ",
  OR: "SEARCH_RESULTS.OR",
  CALENDAR_TODAY: "SEARCH_RESULTS.CALENDAR_TODAY",
  CALENDAR_CANCEL: "SEARCH_RESULTS.CALENDAR_CANCEL",
  CALENDAR_APPLY: "SEARCH_RESULTS.CALENDAR_APPLY",
  EXPORT_SEARCH_RESULT: "SEARCH_RESULTS.EXPORT_SEARCH_RESULT",
  LIMIT_EXCEEDED_TO_DOWNLOAD: "SEARCH_RESULTS.LIMIT_EXCEEDED_TO_DOWNLOAD",
};

export const LABELKEYS_SEARCH_BAR = {
  TITLE: "SEARCH_BAR.TITLE",
};
export const HTTP_STATUS_CODE = {
  STATUS_201: 201,
  STATUS_204: 204,
  STATUS_200: 200,
  STATUS_304: 304,
};

export const LIC_AGREMENT_LEBELS = {
  TITLE: "LIC_AGRREMENT.TITLE",
  DISAGREE: "LIC_AGRREMENT.DISAGREE",
  AGREE: "LIC_AGRREMENT.AGREE",
  DOWNLOAD: "LIC_AGRREMENT.DOWNLOAD",
  LICBODY: "LIC_AGRREMENT.LICBODY",
  TITLE_FULL: "LIC_AGRREMENT.TITLE_FULL",
  LICBODY_PT1: "LIC_AGRREMENT.LICBODY_PT1",
  LICBODY_PT2: "LIC_AGRREMENT.LICBODY_PT2",
  LICBODY_PT3: "LIC_AGRREMENT.LICBODY_PT3",
  LICBODY_PT4: "LIC_AGRREMENT.LICBODY_PT4",
  LICBODY_PT5: "LIC_AGRREMENT.LICBODY_PT5",
  LICBODY_PT6: "LIC_AGRREMENT.LICBODY_PT6",
  LICBODY_PT7: "LIC_AGRREMENT.LICBODY_PT7",
  LICBODY_PT8: "LIC_AGRREMENT.LICBODY_PT8",
  LICBODY_PT9: "LIC_AGRREMENT.LICBODY_PT9",
  LICBODY_PT10: "LIC_AGRREMENT.LICBODY_PT10",
  LICBODY_PT11: "LIC_AGRREMENT.LICBODY_PT11",
  LICBODY_PT12: "LIC_AGRREMENT.LICBODY_PT12",
  LICBODY_PT13: "LIC_AGRREMENT.LICBODY_PT13",
  LICBODY_PT14: "LIC_AGRREMENT.LICBODY_PT14",
  PDF_LINK: "/lic_agreement/Honeywell-Material-License-Agreement.pdf",
  URL_LABEL: "LIC_AGRREMENT.URL_LABEL",
};

export const ADD_TO_FAVOURITE_LEBELS = {
  TITLE: "ADD_TO_FAVOURITE.TITLE",
  REMOVEFAVOURITE_TITLE: "ADD_TO_FAVOURITE.REMOVEFAVOURITE_TITLE",
  ADDTOFAV_MSG: "ADD_TO_FAVOURITE.ADDTOFAV_MSG",
  REMOVETOFAV_MSG: "ADD_TO_FAVOURITE.REMOVETOFAV_MSG",
  REMOVE: "ADD_TO_FAVOURITE.REMOVE",
  CLOSE: "ADD_TO_FAVOURITE.CLOSE",
  PUB_DELETE_MSG: "ADD_TO_FAVOURITE.DELETE_PUB_MSG",
};

export const FAVOURITE_TABLE = {
  TITLE: "FAVOURITE_TABLE.TITLE",
  PUBLICATION_NO: "FAVOURITE_TABLE.PUBLICATION_NO",
  REVISION_DATE_NO: "FAVOURITE_TABLE.REVISION_DATE_NO",
  TYPE: "FAVOURITE_TABLE.TYPE",
  TOP_5: "FAVOURITE_TABLE.TOP_5",
  REMOVE_FAVORITE: "FAVOURITE_TABLE.REMOVE_FAVORITE",
  NO_RECORD_FOUND: "FAVOURITE_TABLE.NO_RECORD_FOUND",
  NO_FAV_ADDED: "FAVOURITE_TABLE.NO_FAV_ADDED",
  TOP_5_FAV: "FAVOURITE_TABLE.TOP_5_FAV",
};

export const WATCHLIST_TABLE = {
  TITLE: "WATCHLIST_TABLE.TITLE",
  DESCRIPTION: "WATCHLIST_TABLE.DESCRIPTION",
  ATA_NO: "WATCHLIST_TABLE.ATA_NO",
  REVISION_NO: "WATCHLIST_TABLE.REVISION_NO",
  MANUALS: "WATCHLIST_TABLE.MANUALS",
  RELATED_PUBS: "WATCHLIST_TABLE.RELATED_PUBS",
  DELETE: "WATCHLIST_TABLE.DELETE",
  LAST_CHANGE_DATE: "WATCHLIST_TABLE.LAST_CHANGE_DATE",
  EXPORT_MY_NOTIFICATIONS: "WATCHLIST_TABLE.EXPORT_MY_NOTIFICATIONS",
};

export const WATCHLIST_POPUP = {
  TITLE: "WATCHLIST_POPUP.TITLE",
  WATCHLISTBODY: "WATCHLIST_POPUP.WATCHLISTBODY",
  VIEW_WATCHLIST: "WATCHLIST_POPUP.VIEW_WATCHLIST",
  GET_EMAIL_UPDATE: "WATCHLIST_POPUP.GET_EMAIL_UPDATE",
  STOP_EMAIL_UPDATE: "WATCHLIST_POPUP.STOP_EMAIL_UPDATE",
  REMOVE_WATCHLIST: "WATCHLIST_POPUP.REMOVE_WATCHLIST",
  REMOVEWATCHLISTBODY: "WATCHLIST_POPUP.REMOVEWATCHLISTBODY",
  REMOVE_WATCHLIST_BTN: "WATCHLIST_POPUP.REMOVE_WATCHLIST_BTN",
  BACK_BUTTON: "WATCHLIST_POPUP.BACK_BUTTON",
  ERROR_WATCHLIST_MSG: "WATCHLIST_POPUP.ERROR_WATCHLIST_MSG",
  PUB_REMOVED: "WATCHLIST_POPUP.PUB_REMOVED",
  ADD_RELATED_PUB: "WATCHLIST_POPUP.ADD_RELATED_PUB",
  SAVE: "WATCHLIST_POPUP.SAVE",
};

export const VIEW_COLLECTION = {
  BACK_SEARCH: "VIEW_COLLECTION.BACK_SEARCH",
  VIEW_COLLECTION_TITLE: "VIEW_COLLECTION.VIEW_COLLECTION_TITLE",
  NO_UPDATES_MSG: "VIEW_COLLECTION.NO_UPDATES_MSG",
};

export const REQUEST_DOWNLOAD_ERROR = {
  TITLE: "REQUEST_DOWNLOAD_ERROR.TITLE",
  ERROR_MSG: "REQUEST_DOWNLOAD_ERROR.ERROR_MSG",
  BACK_BUTTON: "REQUEST_DOWNLOAD_ERROR.BACK_BUTTON",
  FILE_STREAMER: "REQUEST_DOWNLOAD_ERROR.FILE_STREAMER",
};

export const TEMP_REV = {
  TITLE: "TEMP_REV.TITLE",
  TR_NUMBER: "TEMP_REV.TR_NUMBER",
  TR_DATE: "TEMP_REV.TR_DATE",
  REMARKS: "TEMP_REV.REMARKS",
  DOWNLOAD_TR: "TEMP_REV.DOWNLOAD_TR",
};

export const REQUEST_FORM = {
  TITLE: "REQUEST_FORM.TITLE",
  MODAL_BDY: "REQUEST_FORM.MODAL_BDY",
  YES_BTN: "REQUEST_FORM.YES_BTN",
  NO_BTN: "REQUEST_FORM.NO_BTN",
  REQUEST_SUBMITTED: "REQUEST_FORM.REQUEST_SUBMITTED",
  CASENUMBER: "REQUEST_FORM.CASENUMBER",
  REQUEST_DATE: "REQUEST_FORM.REQUEST_DATE",
  REASON_REQ: "REQUEST_FORM.REASON_REQ",
  REQUEST_DES: "REQUEST_FORM.REQUEST_DES",
  CLOSE_BTN: "REQUEST_FORM.CLOSE_BTN",
  SUBMIT_FORM: "REQUEST_FORM.SUBMIT_FORM",
  NAV_TEXT: "REQUEST_FORM.NAV_TEXT",
};

export const DATE_RANGE = {
  SELECT_DATE_RANGE: "DATE_RANGE.SELECT_DATE_RANGE",
};

export const MY_LIBRARY = {
  header: [
    "MY_LIBRARY.TITLE",
    "MY_LIBRARY.PUBLICATION_NO",
    "MY_LIBRARY.ATA_NO",
    "MY_LIBRARY.REVISION_NO",
    "MY_LIBRARY.REQUEST",
  ],
};

export const TOPHEADERTITLE = {
  MY_LIB_TITLE: "TOPHEADERTITLE.MY_LIB_TITLE",
  RECENT_DOWNLOAD_TITLE: "REQUEST_DOWNLOAD.REC_DOWNLOAD",
  DOWNLOADS_TITLE: "DASHBOARD.DOWNLOADS",
  REQUEST_FORM_TITLE: "DASHBOARD.REQUEST_FORM",
};
export const ERROR_MSG = {
  TITILE: "ERROR_MSG.TITILE",
  INVALID_SEARCH_KEYWORD: "ERROR_MSG.INVALID_SEARCH_KEYWORD",
  ERROR_MS: "ERROR_MSG.ERROR_MS",
  NO_DATA_FOUND: "ERROR_MSG.NO_DATA_FOUND",
  UNATHORIZED: "ERROR_MSG.UNATHORIZED",
};

export const REV_REQUEST = {
  TITILE: "REV_REQUEST.TITILE",
  NAME: "REV_REQUEST.NAME",
  EMAIL: "REV_REQUEST.EMAIL",
  PHONE: "REV_REQUEST.PHONE",
  COMPANY: "REV_REQUEST.COMPANY",
  REQUESTOR_INFO: "REV_REQUEST.REQUESTOR_INFO",
  SHIPPING_ADD: "REV_REQUEST.SHIPPING_ADD",
  BACK_LIB: "REV_REQUEST.BACK_LIB",
  SUBMIT_REQ: "REV_REQUEST.SUBMIT_REQ",
};
export const RELATED_PUBLICATION = {
  TITLE: "RELATED_PUBLICATION.TITLE",
};
export const REQUEST_DOWNLOAD = {
  REQ_TITLE: "REQUEST_DOWNLOAD.REQ_TITLE",
};
export const REPORT_DIS = {
  REPORT_TITLE: "REPORT_DIS.REPORT_TITLE",
};

export const MY_FAV_DATA: any = [
  {
    label: "MY_LIBRARY.TITLE",
    data: "pubTitle",
    isTruncate: true,
    linkTo: "/",
    linkParams: "pubNumber",
  },
  {
    label: "MY_LIBRARY.PUBLICATION_NO",
    data: "pubNumber",
  },
  {
    label: "MY_LIBRARY.ATA_NO",
    data: "revDate",
    extraParamData: ["revNumber"],
    extraParamPrefix: ["Rev "],
    isFormatDate: true,
  },
  {
    label: "MY_LIBRARY.REVISION_NO",
    data: "pubTypeName",
  },
  {
    label: "FAVOURITE_TABLE.TOP_5",
    data: "isTopFavourite",
    type: "checkbox",
  },
  {
    label: "MY_LIBRARY.REQUEST",
    data: "",
    linkTo: "/rev_request",
    classname: "requestIcon",
    aria_label: "lib request Icon",
  },
];

export const MY_LIB_DATA: any = [
  {
    label: "MY_LIBRARY.TITLE",
    data: "title",
    isTruncate: true,
    linkTo: "/",
    linkParams: "pubNumber",
  },
  {
    label: "MY_LIBRARY.PUBLICATION_NO",
    data: "pubNumber",
  },
  {
    label: "MY_LIBRARY.ATA_NO",
    data: "ata",
  },
  {
    label: "MY_LIBRARY.REVISION_NO",
    data: "revisionDate",
    extraParamData: ["versionNumber"],
    extraParamPrefix: ["Rev "],
    isFormatDate: true,
  },
  // {
  //   label: "MY_LIBRARY.REQUEST",
  //   data: "",
  //   linkTo: "/rev_request",
  //   classname: "requestIcon",
  //   aria_label: "lib request Icon",
  // },
];

export const DOWNLD_DATA: any = [
  {
    label: "DOWNLOADS.TITLE",
    data: "pubTitle",
    isTruncate: true,
    linkTo: "/",
    linkParams: "pubNumber",
  },
  {
    label: "DOWNLOADS.PUBLICATION_NO",
    data: "pubNumber",
  },

  {
    label: "DOWNLOADS.REVISION_NO",
    data: "revDate",
    extraParamData: ["revNumber"],
    extraParamPrefix: ["Rev "],
    isFormatDate: true,
  },
  {
    label: "DOWNLOADS.TYPE",
    data: "pubTypeName",
  },
  // {
  //   label: "DOWNLOADS.ADD_FAV",
  //   data: "favouriteFlag",
  //   type: "addFavorite",
  //   classname: "favIcon",
  //   aria_label: "lib request Icon",
  // },
];

export const TABLEPAGINATION = {
  RowsPerPage: 50,
  Page: 1,
};
export const TEMP_REVISIONS: any = [
  {
    label: "TEMP_REV.TR_NUMBER",
    data: "trRevisionNumber",
  },
  {
    label: "TEMP_REV.TR_DATE",
    data: "trRevisionDate",
    isFormatDate: true,
  },
  {
    label: "TEMP_REV.REMARKS",
    data: "trRemarks",
    classname: "temp-remarks",
  },
  {
    label: "TEMP_REV.DOWNLOAD_TR",
    data: "",
    trNotReq: false,
    classname: "downloadicon",
    downloadProgress: true,
    type: "download",
  },
];
export const REQUEST_FORM_DATA: any = [
  {
    label: "REQUST_FORM.TITLE",
    data: "title",
    isTruncate: true,
  },
  {
    label: "REQUST_FORM.PUBLICATION_NO",
    data: "pub_number",
  },
  {
    label: "REQUST_FORM.ATA_NO",
    data: "ata",
  },
  {
    label: "REQUST_FORM.REVISION_NO",
    data: "revision_date",
    extraParamData: ["versionNumber"],
    extraParamPrefix: ["Rev "],
    isFormatDate: true,
  },
  {
    label: "Assigned Aircraft",
    data: "unassigned_aircraft",
    trNotReq: true,
    static: true,
    classname: "unassigned-aircraft",
    idname: "unassigned-aircraft",
  },
];

export const UPDATES: any = [
  {
    label: "Manuals",
    value: "",
  },
  {
    label: "Temporary Revisions",
    value: "",
  },
  {
    label: "Related Pubs",
    value: "",
  },
];

export const FILTER_FACETS: any = [
  {
    facetLabel: "FILTER_FACETS.ITEM_AIRCRAFT",
    facetKey: "manufacturers",
    facetFirstLevel: "manufacturers.aircraft_oem",
    facetSecondLevel: "applicationmodels",
    facetSecondLevelField: "applications.application_name",
    otherAttr: "filter_conditions",
    thirdLevel: false,
    secondLevelMultiSelect: true,
    deepLevel: 2,
    deepLevelKey: "applicationmodels",
  },
  {
    facetLabel: "FILTER_FACETS.ITEM_PRODUCT",
    facetKey: "product_family",
    facetFirstLevel: "product_family_productseries.familyname",
    facetSecondLevel: "product_family_productseries",
    facetSecondLevelField: "product_family_productseries.product_series",
    facetThirdLevel: "product_family_productunit",
    facetThirdLevelField: "product_family_productunit.product",
    otherAttr: "filter_conditions",
    secondLevelMultiSelect: false,
    thirdLevel: true,
    deepLevel: 3,
    deepLevelKey: "product_family_productunit",
  },
  {
    facetLabel: "FILTER_FACETS.ITEM_PUBLICATION",
    facetKey: "publication_category_name",
    facetFirstLevel: "publication_category_name.publication_category",
    facetSecondLevel: "publication_type",
    facetSecondLevelField: "publication_type.publication_type_description",
    otherAttr: "filter_conditions",
    thirdLevel: false,
    secondLevelMultiSelect: true,
    deepLevel: 2,
    defaultDisabled: true,
    disabledClass: "facet-disable",
    deepLevelKey: "publication_type",
  },
  {
    facetLabel: "FILTER_FACETS.ITEM_RELEASE",
    facetKey: "release_date",
    facetFirstLevel: "range",
    class: "release-date",
  },
];
export const RELEASE_DATE_VALUES: any = [
  { label: "1 Month", value: 1 },
  { label: "3 Months", value: 3 },
  { label: "6 Months", value: 6 },
  { label: "1 Year", value: 12 },
  { label: "5 Years", value: 60 },
];

export const FACET_LABELS: any = {
  "manufacturers.aircraft_oem": {
    label: "FILTER_FACETS.AIRCRAFT_FIRSTLABEL",
    level: 1,
    facetKey: "manufacturers",
  },
  "product_family_productseries.familyname": {
    label: "FILTER_FACETS.PRODUCT_FIRSTLABEL",
    level: 1,
    facetKey: "product_family",
  },
  "publication_category_name.publication_category": {
    label: "FILTER_FACETS.PUBLICATION_FIRSTLABEL",
    level: 1,
    facetKey: "publication_category_name",
  },
  range: { label: "FILTER_FACETS.RELEASE_FIRSTLABEL" },
  "applications.application_name": {
    label: "FILTER_FACETS.MANUFACTURER_SECONDLEVEL_LABEL",
    level: 2,
    facetKey: "manufacturers",
  },
  "product_family_productseries.product_series": {
    label: "FILTER_FACETS.PRODUCTFAMILY_SECONDLEVEL_LABEL",
    level: 2,
    facetKey: "product_family",
  },
  "product_family_productunit.product": {
    label: "FILTER_FACETS.PRODUCTFAMILY_THIRDLEVEL_LABEL",
    level: 3,
    facetKey: "product_family",
  },
  "publication_type.publication_type_description": {
    label: "FILTER_FACETS.PUBLICATION_CATEGORY_SECOND_LEVEL_LABEL",
    level: 2,
    facetKey: "publication_category_name",
  },
};

export const PUB_CATEGORY_DISABLE_ARR = [
  "manufacturers.aircraft_oem",
  "product_family_productseries.familyname",
];

export const HIDE_RIGHT_PANEL_STATUS = ["cancelled", "divested"];
export const RELEASE_DATE_KEY = "release_date";
export const RELEASE_DATE_RANGE = "range";
export const RELEASE_START_DATE = "fromDate";
export const RELEASE_END_DATE = "toDate";
export const LICENCED = "License";
export const YES_MARK = "Y";
export const SEARCH_RESULTS_ATTRIBUTES: any = [
  {
    label: "SEARCH_RESULTS.RELETED_MODALS",
    data: "RELATED_PRODUCTS",
    maxLength: 5,
    viewAll: true,
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_TYPE",
    data: "PUBLICATION_TYPE_NAME",
    searchCondition: "COLLECTION",
    displayConditionalType: ["PUB_CATEGORY_NAME", "PUB_CATEGORY"],
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_NUMBER",
    data: "PUB_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.ATA_NUMBER",
    data: "ATA_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.REVISION_DATE_NUMBER",
    data: "REVISION_DATE",
    isFormatDate: true,
    extraParamData: ["VERSION_NUMBER"],
    extraParamPrefix: ["Rev "],
    extraParamSeparator: ["/"],
  },
  {
    label: "SEARCH_RESULTS.ORIGINAL_DATE",
    data: "ORIGINAL_DATE",
    isFormatDate: true,
  },
  {
    label: "SEARCH_RESULTS.PUB_ID",
    data: "PUB_ID",
  },

  {
    label: "SEARCH_RESULTS.VOLUME",
    data: "PUB_VOLUME",
  },
  {
    label: "SEARCH_RESULTS.ISSUE_NUMBER",
    data: "ISSUE_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.EXPORT_CLASSIFICATION",
    data: "EXPORT_CONTROL_CODE",
    extraParamData: [
      "EXPORT_CONTROL_JURIDICTION",
      "EXPORT_CONTROL_LIC_DESCRIPTION",
    ],
    extraParamPrefix: ["", ""],
    extraParamSeparator: ["-", "-"],
  },

  {
    label: "SEARCH_RESULTS.PART_NUMBERS",
    data: "PART_NUMBERS",
    maxLength: 5,
    viewAll: true,
  },
  {
    label: "SEARCH_RESULTS.RELETED_APPLICATION",
    data: "RELATED_APPLICATIONS",
    maxLength: 5,
    viewAll: true,
  },

  {
    label: "SEARCH_RESULTS.ORGANIZATION",
    data: "ORG_DESC",
  },
  {
    label: "SEARCH_RESULTS.REMARKS",
    data: "REMARKS",
  },
];

export const COLLECTION_ATTRIBUTES: any = [
  {
    label: "SEARCH_RESULTS.RELETED_MODALS",
    data: "RELATED_PRODUCTS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_TYPE",
    data: "PUBLICATION_TYPE_NAME",
    searchCondition: "COLLECTION",
    displayConditionalType: ["PUB_CATEGORY_NAME", "PUB_CATEGORY"],
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_NUMBER",
    data: "PUB_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.ATA_NUMBER",
    data: "ATA_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.REVISION_DATE_NUMBER",
    data: "REVISION_DATE",
    isFormatDate: true,
    extraParamData: ["VERSION_NUMBER"],
    extraParamPrefix: ["Rev "],
    extraParamSeparator: ["/"],
  },
  {
    label: "SEARCH_RESULTS.ORIGINAL_DATE",
    data: "ORIGINAL_DATE",
    isFormatDate: true,
  },
  {
    label: "SEARCH_RESULTS.PUB_ID",
    data: "PUB_ID",
  },
  {
    label: "SEARCH_RESULTS.VOLUME",
    data: "PUB_VOLUME",
  },
  {
    label: "SEARCH_RESULTS.ISSUE_NUMBER",
    data: "ISSUE_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.EXPORT_CLASSIFICATION",
    data: "EXPORT_CONTROL_CODE",
    extraParamData: [
      "EXPORT_CONTROL_JURIDICTION",
      "EXPORT_CONTROL_LIC_DESCRIPTION",
    ],
    extraParamPrefix: ["", ""],
    extraParamSeparator: ["-", "-"],
  },

  {
    label: "SEARCH_RESULTS.PART_NUMBERS",
    data: "PART_NUMBERS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.RELETED_APPLICATION",
    data: "RELATED_APPLICATIONS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.ORGANIZATION",
    data: "ORG_DESC",
  },
  {
    label: "SEARCH_RESULTS.REMARKS",
    data: "REMARKS",
  },
];

export const TR_UPDATE_ATTRIBUTES: any = [
  {
    label: "TEMP_REV.TR_NUMBER",
    data: "VERSION_NUMBER",
  },
  {
    label: "TEMP_REV.TR_DATE",
    data: "REVISION_DATE",
    isFormatDate: true,
  },
  {
    label: "TEMP_REV.REMARKS",
    data: "REMARKS",
  },
];
export const RELATED_PUBS_ATTRIBUTES: any = [
  {
    label: "SEARCH_RESULTS.RELETED_MODALS",
    data: "RELATED_PRODUCTS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_TYPE",
    data: "PUBLICATION_TYPE_NAME",
    searchCondition: "COLLECTION",
    displayConditionalType: ["PUB_CATEGORY_NAME", "PUB_CATEGORY"],
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_NUMBER",
    data: "PUB_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.ATA_NUMBER",
    data: "ATA_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.REVISION_DATE_NUMBER",
    data: "REVISION_DATE",
    isFormatDate: true,
    extraParamData: ["VERSION_NUMBER"],
    extraParamPrefix: ["Rev "],
    extraParamSeparator: ["/"],
  },
  {
    label: "SEARCH_RESULTS.ORIGINAL_DATE",
    data: "ORIGINAL_DATE",
    isFormatDate: true,
  },
  {
    label: "SEARCH_RESULTS.PUB_ID",
    data: "PUB_ID",
  },
  {
    label: "SEARCH_RESULTS.VOLUME",
    data: "PUB_VOLUME",
  },
  {
    label: "SEARCH_RESULTS.ISSUE_NUMBER",
    data: "ISSUE_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.EXPORT_CLASSIFICATION",
    data: "EXPORT_CONTROL_CODE",
    extraParamData: [
      "EXPORT_CONTROL_JURIDICTION",
      "EXPORT_CONTROL_LIC_DESCRIPTION",
    ],
    extraParamPrefix: ["", ""],
    extraParamSeparator: ["-", "-"],
  },

  {
    label: "SEARCH_RESULTS.PART_NUMBERS",
    data: "PART_NUMBERS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.RELETED_APPLICATION",
    data: "RELATED_APPLICATIONS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.ORGANIZATION",
    data: "ORG_DESC",
  },
  {
    label: "SEARCH_RESULTS.REMARKS",
    data: "REMARKS",
  },
];

export const TEMPORARY_REVISIONS_ATTRIBUTES: any = [
  {
    label: "SEARCH_RESULTS.RELETED_MODALS",
    data: "RELATED_PRODUCTS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_TYPE",
    data: "PUBLICATION_TYPE_NAME",
    searchCondition: "COLLECTION",
    displayConditionalType: ["PUB_CATEGORY_NAME", "PUB_CATEGORY"],
  },
  {
    label: "SEARCH_RESULTS.PUBLICATION_NUMBER",
    data: "PUB_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.ATA_NUMBER",
    data: "ATA_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.REVISION_DATE_NUMBER",
    data: "REVISION_DATE",
    isFormatDate: true,
    extraParamData: ["VERSION_NUMBER"],
    extraParamPrefix: ["Rev "],
    extraParamSeparator: ["/"],
  },
  {
    label: "SEARCH_RESULTS.ORIGINAL_DATE",
    data: "ORIGINAL_DATE",
    isFormatDate: true,
  },
  {
    label: "SEARCH_RESULTS.PUB_ID",
    data: "PUB_ID",
  },
  {
    label: "SEARCH_RESULTS.VOLUME",
    data: "PUB_VOLUME",
  },
  {
    label: "SEARCH_RESULTS.ISSUE_NUMBER",
    data: "ISSUE_NUMBER",
  },
  {
    label: "SEARCH_RESULTS.EXPORT_CLASSIFICATION",
    data: "EXPORT_CONTROL_CODE",
    extraParamData: [
      "EXPORT_CONTROL_JURIDICTION",
      "EXPORT_CONTROL_LIC_DESCRIPTION",
    ],
    extraParamPrefix: ["", ""],
    extraParamSeparator: ["-", "-"],
  },

  {
    label: "SEARCH_RESULTS.PART_NUMBERS",
    data: "PART_NUMBERS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.RELETED_APPLICATION",
    data: "RELATED_APPLICATIONS",
    maxLength: 5,
  },
  {
    label: "SEARCH_RESULTS.ORGANIZATION",
    data: "ORG_DESC",
  },
  {
    label: "SEARCH_RESULTS.REMARKS",
    data: "REMARKS",
  },
];

export const RELATED_PUBLICATION_BUTTON: any = [
  {
    key: "request_download",
    class: "requestdownload",
    label: "Download Publication",
  },
  {
    key: "request_publication",
    class: "requestPublication ",
    label: "Request Publication",
  },
  {
    key: "request_subscription",
    class: "requestSubscription ",
    label: "Request Subscription",
  },
  {
    key: "request_collection",
    class: "requestCollection ",
    label: "Request Collection",
  },
];

export const APPLY_FILTER = {
  TITLE: "APPLY_FILTER.TITLE",
  APPLY_TITLE: "APPLY_FILTER.APPLY_TITLE",
};

export const RECENT_DOWNLOAD: any = [
  {
    label: "MY_LIBRARY.TITLE",
    data: "pubTitle",
    isTruncate: true,
    linkTo: "/",
    linkParams: "pubNumber",
  },
  {
    label: "FAVOURITE_TABLE.PUBLICATION_NO",
    data: "pubNumber",
  },
  {
    label: "FAVOURITE_TABLE.REVISION_DATE_NO",
    data: "revDate",
    extraParamData: ["revNumber"],
    extraParamPrefix: ["Rev "],
    isFormatDate: true,
  },
  {
    label: "FAVOURITE_TABLE.TYPE",
    data: "pubTypeName",
  },
  {
    label: "REQUEST_DOWNLOAD.ADD_FAV",
    data: "favouriteFlag",
    type: "addFavorite",
    aria_label: "lib request Icon",
  },
];
export const MODAL_MESSAGE = {
  TEMP_REVISION_MSG: "MODAL_MESSAGE.TEMP_REVISION_MSG",
  RECENT_DOWNLD_MSG: "MODAL_MESSAGE.RECENT_DOWNLD_MSG",
  YES: "MODAL_MESSAGE.YES",
};

export const DEFAULT_SHOW_MORE_LENGTH = "5";
export const SUPPORT_AND_RESOURCES = "Support & Resources";
export const TECHNICAL_PUBLICATIONS = "Technical Publications";
export const RESTRICT_APPLICATION_TEXT =
  "You do not have access to Technical Publications application, Please request access from admin tool";
export const COLLECTION_OPERATION = "UPDATE";
export const RECORDS_PER_PAGE_VIEW_ALL = "30";
export const RECORDS_PER_COLUMN_VIEW_ALL = 10;
export const RECORDS_PER_PAGE_SEARCH_RESULTS = 50;
export const A2A_INDICATOR = "2a2a_indicator";
export const CHINA_COUNTRY_CODE = "CN";
export const NULL_VALUE = "N/A";
export const REV_TEXT = "/Rev ";
export const ADDFAVORITE = "addFavorite";
export const SUBSCRIPTION_RENEWAL_VALUE = "y";
export const SUBSCRIPTION_ERROR_MSG = "Error fetching SubscriptionCount";
export const SUBSCRIPTION_TEXT = "Subscriptions About to Expire";
export const PUBID_ERROR_MSG = "Error fetching PubId";
export const AIRCRAFT_DETAILS = "Aircraft Details";
export const OTHER_DETAILS = "Other";
export const APPLICATION_DETAILS = "Application";
export const REQUEST_FORM_APPLICATIONS =
  "Please provide the details about the aircraft that will require this publication";
export const SUBSCRIPTION_URL = "https://stageaeroservices.honeywell.com/";
export const NEW_TAB_OPEN = "_blank";
export const DOWNLOAD_OPTIONS = "Download Options";
export const PARTIAL_DOWNLOAD_CONTENT =
  "This Publication is available in multiple presentation formats. Select an option to proceed.";
export const PUBLICATION_TYPE_MANUAL = "manual";
export const PUBLICATION_TYPE_BULLETIN = "bulletin";
export const PUBLICATION_TYPE_LETER = "letter";
export const PUBLICATION_TYPE_OTHERS = "other";
export const PUBLICATION_TYPE_INDEX = "index";
export const PUBLICATION_TYPE_MARKETING = "marketing";
export const RECENT_DOWNLOAD_VIEW_SHOW_COUNT = 5;
export const PUBLICATION_MEDIA_TYPE_NAME = "pdf";
export const NULL_CHECK = null;
export const TRUE_CHECK = "true";
export const REPORT_DISCREPANCY_OPEN_LINK =
  "https://qtechpubdiscrepancy.honeywell.com/report-discrep";
export const BLANK_LINK = "_self";
export const UNASSIGNED_AIRCRAFT = "unassigned_aircraft";
export const DOWNLOAD_TEXT = "download";
export const BROWSE_PUBLICATIONS = "Browse Publications";
export const REQUEST_FORM_TEXT = {
  ABOUT: "REQUEST_FORM.ABOUT",
  ACTIVE_SERVICE_AGREEMENT: "REQUEST_FORM.ACTIVE_SERVICE_AGREEMENT",
  TERMS_AND_CONIDTIONS: "REQUEST_FORM.TERMS_AND_CONIDTIONS",
  UNASSIGNED: "REQUEST_FORM.UNASSIGNED",
  REQUESTOR_INFORMATION: "REQUEST_FORM.REQUESTOR_INFORMATION",
};

export const ADMIN_SITE = {
  TITLE: "ADMIN_SITE.TITLE",
  LIC_DIS: "ADMIN_SITE.LIC_DIS",
  LIC_NONDIS: "ADMIN_SITE.LIC_NONDIS",
  PUBLICATION_TITLE: "ADMIN_SITE.PUBLICATION_TITLE",
};

export const LIC_DISCREPANCY = "https://utechpubdiscrepancy.honeywell.com/";
export const NONLIC_DISCREPANCY =
  "https://utechpubdiscrepancynlic.honeywell.com/";
export const PUBLICATION_TITLE =
  "https://utechpubpublishing.honeywell.com/publication";
export const honeywellFAQLink =
  "https://aerospace.honeywell.com/us/en/support-and-resources/knowledge-and-training/training/topics/allow-pop-ups-browser-technical-publications";

export const DOWNLOAD_PDF = {
  TITLE: "DOWNLOAD_PDF.TITLE",
  HEADING: "DOWNLOAD_PDF.HEADING",
  FETCHING: "DOWNLOAD_PDF.FETCHING",
  DWNLD_COMPLETE: "DOWNLOAD_PDF.DWNLD_COMPLETE",
  SUB_HEADING: "DOWNLOAD_PDF.SUB_HEADING",
  CLOSE: "DOWNLOAD_PDF.CLOSE",
  CANCEL_DOWNLOAD: "DOWNLOAD_PDF.CANCEL_DOWNLOAD",
  ERROR_MSG: "DOWNLOAD_PDF.ERROR_MSG",
  ERROR_TITLE: "DOWNLOAD_PDF.ERROR_TITLE",
  YES: "DOWNLOAD_PDF.YES",
  NO: "DOWNLOAD_PDF.NO",
  DOWNLOAD_COMPLETE: "DOWNLOAD_PDF.DOWNLOAD_COMPLETE",
  CLOSE_WINDOW: "DOWNLOAD_PDF.CLOSE_WINDOW",
  BLOCKED_TITLE: "DOWNLOAD_PDF.BLOCKED_TITLE",
  BLOCKED_MESSAGE: "DOWNLOAD_PDF.BLOCKED_MESSAGE",
  BLOCKED_LINK_TEXT: "DOWNLOAD_PDF.BLOCKED_LINK_TEXT",
};

export const REQUEST_ERRORMODAL = {
  ERROR_MSG: "REQUEST_ERRORMODAL.ERROR_MSG",
  RETRY_BTN: "REQUEST_ERRORMODAL.RETRY_BTN",
  CANCEL_BTN: "REQUEST_ERRORMODAL.CANCEL_BTN",
};

export const VIEW_INTERACTIVE_URL = {
  INTERACTIVE_TITLE: "VIEW_INTERACTIVE_URL.INTERACTIVE_TITLE",
};
export const PARTIAL_PUBLICATION_VALUES = [
  { key: "pdf", value: "PDF", label: "Publication (PDF)" },
  { key: "partial", value: "PARTIAL", label: "Partial Revision" },
  { key: "doc", value: "DOC", label: "DOC" },
  { key: "docx", value: "DOCX", label: "DOCX" },
  { key: "ebook", value: "EBOOK", label: "EBOOK" },
  { key: "ppt", value: "PPT", label: "PPT" },
  { key: "pptx", value: "PPTX", label: "PPTX" },
  { key: "swf", value: "SWF", label: "SWF" },
  { key: "text", value: "Text", label: "Text" },
  { key: "xls", value: "XLS", label: "XLS" },
  { key: "xlsx", value: "XLSX", label: "XLSX" },
  { key: "xml", value: "XML", label: "XML" }
];
export const ITEM_URL =
  "https://r4icdmsserver.honeywell.com/R4iWebService_4.0.5/R4iViewer.asmx";

export const PATHNAMEURL = "/";
export const REQUEST_DOWNLOAD_MESSAGE = {
  INTERNAL_MANUAL_VIEW_SFDC:
    "REQUEST_DOWNLOAD_MESSAGE.INTERNAL_MANUAL_VIEW_SFDC",
  EXTERNAL_USER_VIEW_SFDC: "REQUEST_DOWNLOAD_MESSAGE.EXTERNAL_USER_VIEW_SFDC",
  HEADLINE: "REQUEST_DOWNLOAD_MESSAGE.HEADLINE",
  LIMITED_IN_DISTRIBUTION: "REQUEST_DOWNLOAD_MESSAGE.LIMITED_IN_DISTRIBUTION",
  BUTTON: "REQUEST_DOWNLOAD_MESSAGE.BUTTON",
  REMARKS: "REQUEST_DOWNLOAD_MESSAGE.REMARKS",
  CANCEL: "REQUEST_DOWNLOAD_MESSAGE.CANCEL",
  NON_LICENCED_VIEW: "REQUEST_DOWNLOAD_MESSAGE.NON_LICENCED_VIEW",
  ACKNOWLEDGE: "REQUEST_DOWNLOAD_MESSAGE.ACKNOWLEDGE",
};
export const URL_SHOP_PART_NUMBER =
  process.env.REACT_APP_APP_ID === "75"
    ? "https://aerospace.honeywell.com/shop/aero/en/p"
    : "https://aerospace.honeywell.com/shop/aero/en/p";
